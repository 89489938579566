import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import Table from "../../../components/table";


function IndexPage() {

  const columns = useMemo(
    () => [
      {
        Header: "Navn",
        accessor: "name",
      },
      {
        Header: "Runde",
        accessor: "round",
      },
      {
        Header: "Hjemme",
        accessor: "homeTeam.name"
      },
      {
        Header: "",
        accessor: "result.awayScore45"
      },
      {
        Header: "",
        accessor: "result.awayScore90"
      },
      {
        Header: "Borte",
        accessor: "awayTeam.name"
      },
      {
        Header: "Tidspunkt",
        accessor: "timestamp",
        Cell: (date) => (
          <div>
            {new Date(date.cell.value).toLocaleDateString("no-NO")}
          </div>
        ),
      },

    ],
    []
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios("https://api.nifs.no/stages/682948/matches/?getActiveRound=1");
      setData(result.data);
    })();
  }, []);


  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
    <div className="w-full">
      <Table type="scores" hidden="name" title="Neste kamper" columns={columns} data={data} />
    </div>
    </Layout>
  );
}

export default IndexPage;
